import React from 'react'
import Figure from './Figure'
import BlogImage from "./BlogImage";
import InstagramEmbed from 'react-instagram-embed'


const AuthorReference = ({ node }) => {
  if (node && node.author && node.author.name) {
    return <span>{node.author.name}</span>;
  }
  return <></>;
};

const serializers = {
  types: {
    authorReference: AuthorReference,
    mainImage: ({ node }) => <BlogImage mainImage={node} className="container mx-auto mt-6 mb-6" />,
    instagramPost: ({ node }) => {
      if (!node.url) return null;
      return <InstagramEmbed url={node.url} className="container mx-auto mt-6 mb-6" />;
    }
  }
}

export default serializers
